/* eslint-disable react/jsx-props-no-spreading */
import Head from 'next/head';
import React from 'react';

import commissionerLight from '../src/assets/commissioner-webfont/commissioner-v20-cyrillic_cyrillic-ext_latin-300.woff2';
import commissionerSemiBold from '../src/assets/commissioner-webfont/commissioner-v20-cyrillic_cyrillic-ext_latin-500.woff2';
import commissionerBold from '../src/assets/commissioner-webfont/commissioner-v20-cyrillic_cyrillic-ext_latin-700.woff2';
import commissionerRegular from '../src/assets/commissioner-webfont/commissioner-v20-cyrillic_cyrillic-ext_latin-regular.woff2';
import jetBrainsMonoLight from '../src/assets/jet-brains-mono-webfont/jetbrains-mono-v18-cyrillic_cyrillic-ext_latin-300.woff2';
import jetBrainsMonoRegular from '../src/assets/jet-brains-mono-webfont/jetbrains-mono-v18-cyrillic_cyrillic-ext_latin-regular.woff2';
import '../src/boot.client';
import '../src/styles/global.scss';

export default function App({ Component, pageProps }) {
  return (
    <>
      <Head>
        <link rel="icon" href="/favicon.svg" type="image/svg+xml" />
        <title>JetLex</title>
        <meta name="description" content="JetLex" />
        {/* <meta name="viewport" content="width=device-width, user-scalable=no" /> */}
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="canonical" href="https://jetlex.com/" />
        <link
          rel="preload"
          href={commissionerLight}
          as="font"
          type="font/woff2"
        />
        <link
          rel="preload"
          href={commissionerRegular}
          as="font"
          type="font/woff2"
        />
        <link
          rel="preload"
          href={commissionerSemiBold}
          as="font"
          type="font/woff2"
        />
        <link
          rel="preload"
          href={commissionerBold}
          as="font"
          type="font/woff2"
        />
        <link
          rel="preload"
          href={jetBrainsMonoLight}
          as="font"
          type="font/woff2"
        />
        <link
          rel="preload"
          href={jetBrainsMonoRegular}
          as="font"
          type="font/woff2"
        />
      </Head>
      <Component {...pageProps} />
    </>
  );
}
